import React from "react";
import { Link } from "gatsby"
import { useTranslation } from "react-i18next";

require ('./footer.scss')

export default () => {
  // Will use default Namespace.
  const { t } = useTranslation();
  return (
    <footer>
      <div className="footer-container">
            <div className="footer-top-area pt-50 pb-20">                        
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="single-footer-widget footer-sitemap-widget">
                                <div className="footer-title footer-sitemap-title">
                                    <h3 className="text-left text-sm-center text-md-left">{t('sitemap')}</h3>
                                </div>
                                <ul className="link-widget text-sm-center">
                                    <li className="active"><Link to={t('home-link')}>{t('home')}</Link></li>
                                    <li><Link to={t('contact-link')}>{t('contact')}</Link></li>

                                    <li className="main_link">{t('restaurant')}</li>
                                    <li><Link to={t('restaurant-link')}>{t('menu')}</Link></li>
                                    <li><Link to={t('drink-link')}>{t('drinks')}</Link></li>
                                    {/*<li><Link to={t('xmas-menu-link')}>{t('xmas-menu')}</Link></li>*/}

                                    <li className="main_link">{t('services')}</li>
                                    {/*<li><Link to={t('sports-rental-link')}>{t('sports-rental')}</Link></li>*/}
                                    <li><Link to={t('luxury-car-link')}>{t('luxury-car')}</Link></li>
                                    <li><Link to={t('wellness-link')}>{t('wellness')}</Link></li>
                                    <li><Link to={t('pool-link')}>{t('pool')}</Link></li>

                                    <li className="main_link">{t('hotel')}</li>
                                    <li><Link to={t('rooms-link')}>{t('rooms')}</Link></li>
                                    <li><Link to={t('about-us-link')}>{t('about-us')}</Link></li>
                                    <li><Link to={t('news-link')}>{t('news')}</Link></li>
                                    <li><Link to={t('events-link')}>{t('events')}</Link></li>
                                    <li><Link to={t('rules-link')}>{t('rules')}</Link></li>
                                    {/*<li className="blank-link"><Link to={t('/')}></Link></li>*/}

                                    {/*<li className="second_column main_link">{t('offers')}</li>
                                    <li className="second_column"><Link to={t('xmas-offer-link')}>{t('xmas-offer')}</Link></li>*/}
                                    
                                    <li className="second_column main_link">{t('occasion')}</li>
                                    <li className="second_column"><Link to={t('family-days-link')}>{t('family-days')}</Link></li>
                                    <li className="second_column"><Link to={t('birthday-link')}>{t('birthday')}</Link></li>
                                    <li className="second_column"><Link to={t('conference-link')}>{t('conference')}</Link></li>
                                    <li className="second_column"><Link to={t('wedding-link')}>{t('wedding')}</Link></li>
                                    <li className="second_column"><Link to={t('kidparty-link')}>{t('kidparty')}</Link></li>
                                    <li className="second_column"><Link to={t('xmas-events-link')}>{t('xmas-events')}</Link></li>

                                    <li className="second_column main_link">{t('carriers')}</li>
                                    <li className="second_column"><Link to={t('event-manager-link')}>{t('event-manager-carrier')}</Link></li>

                                    <li className="second_column"><Link to={t('waiter-link')}>{t('waiter-carrier')}</Link></li>
                                    <li className="second_column"><Link to={t('front-office-manager-link')}>{t('front-office-manager-carrier')}</Link></li>
                                    <li className="second_column"><Link to={t('deputy-chef-link')}>{t('deputy-chef-carrier')}</Link></li>
                                    <li className="second_column"><Link to={t('receptionist-link')}>{t('receptionist-carrier')}</Link></li>
                                    <li className="second_column"><Link to={t('janitor-link')}>{t('janitor-carrier')}</Link></li>
                                    <li className="second_column"><Link to={t('maid-link')}>{t('maid-carrier')}</Link></li>
                                    <li className="second_column"><Link to={t('kitchen-worker-link')}>{t('kitchen-worker-carrier')}</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="single-footer-widget mb-35 footer-logo">
                                <img className="homoky_logo" src="/images/logo/hotel_homoky_white.svg" alt="Homoky hotels logo" />
                            </div>
                            <div className="single-footer-widget mb-35">
                                <div className="footer-title text-sm-center text-md-center">
                                    <h3>{t('lets-connect')}</h3>
                                </div>
                                <ul className="social-icons text-sm-center text-md-center">
                                    <li><a href="https://www.facebook.com/hoteltiliana" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/tilianahotel" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                </ul>
                            </div>
                            <div className="gdpr">
                                <Link to={t('gdpr-link')}>
                                    <p>
                                        {t('gdpr-link-text')}
                                    </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <p>Copyright &copy; <Link to={t('home-link')}>Homoky Hotels</Link> {t('rights-reserved')}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-design-by text-right">
                                <p>
                                    {t('created-by')}
                                    <a href="http://dobom.hu">Dobom Kft.</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
};